/* *,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
} */

:root {
  --clr-btn-bg: #393e46;
  --clr-btn: white;
  --margin-btm: 7rem;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.outlet {
  margin-bottom: var(--margin-btm);
}

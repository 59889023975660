.features-wrapper {
  background-color: var(--clr-beige-lighter);
  text-align: center;
  padding: 3rem 15%;
}

.features-header {
  padding: 0rem 2rem;
}

.features-header h2 {
  font-size: 2rem; /* Adjust the size as needed */
  margin-bottom: 0.5rem;
  color: var(--clr-feature-h);
}

.features-header p {
  margin-bottom: 2rem;
  color: #666;
}

.features-section {
  padding: 1rem 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.feature {
  width: 25%; /* 4 items in a row for widths >= 1024px */
  padding: 0.5rem 2rem;
  box-sizing: border-box;
}

.feature h3 {
  margin-top: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2vw;
}

.features-header p,
.feature p {
  margin-top: 0.5em;
  font-size: 1.1vw;
  text-align: left;
  color: rgb(97, 92, 92);
  line-height: 1.5;
}

@media (max-width: 1023px) {
  .feature {
    width: 50%; /* 2 items in a row */
  }
  .feature h3 {
    font-size: 1.2rem;
  }
  .features-header p,
  .feature p {
    font-size: 1rem;
  }
}

/* When the window width is less than 768px, show 1 feature per row */
@media (max-width: 767px) {
  .feature {
    width: 100%;
  }
  .features-wrapper {
    padding: 2rem 0;
  }
}

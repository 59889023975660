.nav-login-link {
  background-color: transparent;
  /* color: white; */
  border: 0.5px solid #636363;
  border-radius: 4px;
  /* padding: 0.5rem 1rem; */
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.2s;
}

.nav-login-link:hover,
.nav-login-link:focus {
  background-color: #636363;
  color: white;
  text-decoration: none;
}

.nav-login-link {
  margin-left: 1rem;
}

/* Overwrite CSS */
.dropdown-menu {
  min-width: 7rem;
  text-align: center;
  font-size: large;
}

.dropdown-menu[data-bs-popper] {
  left: -2rem;
}

.dropdown-item:hover {
  background-color: #efefef;
}

.nav-link {
  font-size: large;
}

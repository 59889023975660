.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--clr-beige-light);
}

.register-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.register-button {
  background-color: var(--clr-btn-bg);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
}

.register-button:hover {
  background-color: var(--clr-btn-hover-bg);
  transition: all 0.3s ease-out;
  transition: 250ms;
  color: var(--clr-btn-hover);
}

.register-actions .register-link {
  color: var(--clr-link);
  /* text-decoration: none; */
  display: block; /* Stack the link */
  text-align: center;
  margin-top: 1rem;
}

/* Indicate required fields with a red asterisk */
input:required::placeholder:after,
label:after {
  content: " *";
  color: red;
}

.err {
  color: #b33a3a;
}

/* Responsive Styles */
@media (max-width: 400px) {
  .register-container {
    background-color: white;
    padding: 0;
    margin: 0;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
  }

  .register-form {
    border-radius: 0;
    box-shadow: none;
    max-width: 80%;
    padding: 1rem;
  }
}

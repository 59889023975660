/* .footer {
  margin-bottom: 0;
  margin-top: 3rem;
  background-color: rgb(244, 244, 229);
  color: var(--clr-white);
  display: flex;
  justify-content: space-around;
  padding: 3rem 2rem 1rem;
} */

html,
body {
  height: 100%;
  margin: 0;
}

.footer {
  margin-top: auto; /* 这将确保当内容不足以填满屏幕时，页脚被推到底部 */
  /* background-color: rgb(244, 244, 229); */
  background-color: #f2f3f3;
  color: var(--clr-white);
  display: flex;
  justify-content: space-around;
  padding: 3rem 2rem 1rem;
}

.footer-column h5 {
  cursor: default;
  margin-bottom: 1.5rem;
  position: relative;
  font-size: 1rem;
}

.footer-column h5::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 50px; /* underline width */
  height: 4px; /* underline thickness */
  background-color: var(--clr-blue);
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.footer-column ul li {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 100;
  list-style-type: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and movement */
}

.footer-column ul li:hover {
  color: var(--clr-blue);
  transform: translateX(10px); /* Move text to the right */
}

.footer-column a {
  color: inherit;
  text-decoration: none;
}

.social-icons {
  /* font-size: 1.5rem; */
  font-size: 3rem;
}

.social-icon {
  object-fit: contain;
  margin-right: 2rem;

  max-width: 7rem;
  max-height: 7rem;
}

/* Remove margin for the last icon */
.social-icon:last-child {
  margin-right: 0;
}

.footer-bottom {
  text-align: center;
  padding-top: 1rem; /* Add space above the copyright */
  padding-bottom: 1rem; /* Add space below the copyright */
  color: #aaa; /* Lighter text color */
  width: 100%; /* Ensure full width */
}

/* Responsive design */
@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-column {
    margin-bottom: 1rem;
  }

  .social-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .social-icon {
    object-fit: contain;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.affiliation-container {
  text-align: center;
  padding: 2rem 15%;
}

.affiliation-container h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--clr-feature-h);
}

.affiliation-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.affiliation-logo img {
  max-height: 7rem;
  max-width: 7rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .affiliation-container {
    padding: 2rem 5%;
  }
  .affiliation-logo img {
    max-height: 5rem;
    max-width: 5rem;
    margin-bottom: 0.25rem;
  }
}

@media (max-width: 480px) {
  .affiliation-logos {
    padding-top: 1rem;
    gap: 0.5rem;
  }
  .affiliation-logo {
    padding: 0;
    flex: 1 0 50%; /* Grow to fill, don't shrink, and start basis at 50% */
    max-width: 33.3%; /* Optionally ensure the logo does not exceed 50% width */
  }
}

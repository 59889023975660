.ratio-16x9 {
  position: relative;
  width: 100%;
  /* padding-top: 56.25%; */
  padding-top: 48%;
}

.ratio-16x9 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-carousel-container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

:root {
  --handle-size: 3rem;
  /* it was 0.25rem */
  --img-gap: 0.5rem;
  --slider-padding-top-btm: 0rem;
}

.row-container {
  background-color: inherit;
  margin-top: 1rem;
}

.carousel-container {
  display: flex;
  justify-content: center;
  /* overflow: hidden; */
  overflow-x: clip;
}

.slider {
  --items-per-screen: 5;
  --slider-index: 0;
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(calc(var(--slider-index) * -100%));
  transition: transform 250ms ease-in-out;
  /* testing */
  padding: var(--slider-padding-top-btm) 0;
}

/* .slider > img {
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
  aspect-ratio: 3 / 2;
  padding: var(--img-gap);
  border-radius: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0s;
  object-fit: cover;
} */

.card-link {
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
  aspect-ratio: 16 / 9;
  padding: var(--img-gap);
  border-radius: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0s;
  object-fit: cover;
  text-decoration: none;
  color: inherit;
}

.card-link > img {
  object-fit: cover;
  border-radius: 0.5rem;
  height: 100%;
  /* width: 100%; */
}

.slider > .card-link:hover {
  transform: scale(1.2);
  z-index: 1;
  box-shadow: -8px 0 8px rgba(110, 110, 110, 0.5),
    8px 0 8px rgba(110, 110, 110, 0.5);
  padding: 0;
}

.handle {
  padding: 1rem 0;
  border: none;
  border-radius: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 10;
  margin: calc(var(--img-gap) + var(--slider-padding-top-btm)) 0;
  width: var(--handle-size);
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 0;
  transition: background-color 150ms ease-in-out;
}

.left-handle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.right-handle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.handle:hover,
.handle:focus {
  background-color: rgba(255, 255, 255, 0.5);
}

.text {
  transition: transform 150ms ease-in-out;
}

.handle:hover .text,
.handle:focus .text {
  transform: scale(1.2);
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc(var(--img-gap) * 2 + var(--handle-size));
  align-items: center;
}

.row-title {
  font-size: 1.2em;
  margin: 0;
  /* color: var(--clr-row-heading); */
  color: #3d3d3d;
  position: relative;
  padding-left: 20px; /* Adjust the padding to suit the spacing you want */
}

.row-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 63.3%; /* Adjust the height as needed */
  width: 4px; /* Adjust the width for the thickness of the line */
  background-color: orange; /* Change the color as needed */
}

.progress-bar {
  display: flex;
  gap: 0.25rem;
}

.progress-item {
  flex: 0 0 1.5rem;
  min-width: 1.5rem;
  height: 0.5rem;
  background-color: rgba(255, 255, 255, 0.5);
}

.progress-item.active {
  background-color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 1400px) {
  .slider {
    --items-per-screen: 4;
  }
}

@media (max-width: 992px) {
  .slider {
    --items-per-screen: 3;
  }
}

@media (max-width: 600px) {
  .slider {
    --items-per-screen: 2;
  }
}

body {
  background-color: #fff;
  color: rgb(167, 105, 105);
}

:root {
  --clr-btn-bg: black;
  --clr-btn: white;
  --clr-btn-hover-bg: grey;
  --clr-btn-hover: black;
  /* --clr-link: var(--clr-beige-dark); */
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--clr-beige-light);
}

.err {
  color: #b33a3a;
}

.login-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-header {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding-bottom: 2rem;
}

.login-header > .auth-logo > img {
  width: 20%;
  height: 20%;
}

.login-header > .auth-logo > p {
  font-size: 1.5rem;
  color: #444;
  font-weight: 700;
}

.login-header > .auth-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem; /* Increase font size for better readability */
}

/* Style for placeholder to make it clear it is an input field */
.input-group input::placeholder {
  color: #888888; /* Placeholder text color */
}

.login-button {
  background-color: var(--clr-btn-bg);
  color: var(--clr-btn);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
}

.login-button:hover {
  background-color: var(--clr-btn-hover-bg);
  transition: all 0.3s ease-out;
  transition: 250ms;
  color: var(--clr-btn-hover);
}

.login-actions {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-actions,
.login-link {
  color: var(--clr-link);
  /* text-decoration: none; */
  text-align: center;
  font-size: 1rem;
}

.login-link {
  padding-top: 0.5rem;
}

/* Responsive Styles */
@media (max-width: 400px) {
  .login-container {
    background-color: white;
    padding: 0;
    margin: 0;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
  }

  .login-form {
    background-color: white;
    border-radius: 0;
    box-shadow: none;
    max-width: 80%;
    padding: 1rem;
  }

  /* .login-actions,
  .login-link {
    padding-top: 1rem;
    font-size: 1rem;
  } */
}

.card {
  border-width: 0;
}

.tutor-name {
  color: blue;
  font-weight: 900;
}

.group-heading {
  margin: 3rem 0 0 0;
  /* font-size: 1.2em; */
  color: #3d3d3d;
  position: relative;
  padding-left: 16px;
}

.group-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50%; /* Adjust the height as needed */
  width: 4px; /* Adjust the width for the thickness of the line */
  background-color: orange; /* Change the color as needed */
}
